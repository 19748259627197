<section id="reviewModel">
  <div class="row">
    <div class="modal-header col-12">
      <div class="modal-title">
        REVIEW MODEL
        <div *ngIf="!errorMessage" class="float-end closeX" (click)="modal.dismissAll(false)">
          <fa-icon [icon]="['fal', 'times']"></fa-icon>
        </div>
      </div>

    </div>
  </div>

  <div class="row mt-4" *ngIf="errorMessage">
    <div class="col-8 offset-2">
      <div class="row">
        <div class="error-box">
          <p>{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showChart" id="chart-container" class="position-relative w-100 d-flex justify-content-center align-items-center my-3">
    <div>
      <app-model-progress-chart [counts]="counts" [width]="225"></app-model-progress-chart>
    </div>
    <div class="col-12" *ngIf="!showChart">
      <div class="la-ball-beat ball-beat-color text-center">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="bignum">{{counts.currentTopics}}</div>
  </div>

  <div *ngIf="showChart" class="row">
    <div class="col-8 offset-2">
      <div class="countTable">
        <div class="border-cell-corner-top labeled">
          <div><span>LABELED</span></div>
          <div>{{counts.userLabeled}}</div>
          <div class="sug">+</div>
          <div class="sug">{{counts.suggestionLabeled}}</div>
        </div>
        <div class="border-cell-corner-bottom unclear">
          <div><span>UNCLEAR</span></div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>{{counts.userUnclear}}</div>
        </div>
        <div class="border-cell-corner-top merged">
          <div><span>MERGED</span></div>
          <div>{{counts.userMerged}}</div>
          <div class="sug">+</div>
          <div class="sug">{{counts.suggestionMerged}}</div>
        </div>
        <div class="border-cell-corner-bottom custom">
          <div><span>CUSTOM</span></div>
          <div>{{counts.custom}} / {{maxCustomTopics}}</div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="showChart && (counts.blank || counts.suggestions || hasDuplicateTopicNames || isCustomTopicsUnseeded())">

    <div class="text-center mt-4">
      <div *ngIf="isCustomTopicsUnseeded()" class="nameError">
        <fa-icon [icon]="['fal', 'exclamation-triangle']"></fa-icon>
        You have {{counts.customWithoutSeed}} Custom Topics with no seed phrase.
      </div>
      <div *ngIf="counts.blank" class="nameError">
        <fa-icon [icon]="['fal', 'exclamation-triangle']"></fa-icon>
        You have {{counts.blank}} unlabeled topics.
      </div>
      <div *ngIf="hasDuplicateTopicNames" class="nameError">
        <fa-icon [icon]="['fal', 'exclamation-triangle']"></fa-icon>
        Multiple topics have the same name. Please rename them to continue.
      </div>
      <div *ngIf="counts.suggestions">
        You have {{counts.suggestions}} unreviewed topics.
      </div>
      <div *ngIf="!isActionDisabled()">
        <fa-icon [icon]="['fal', 'exclamation-triangle']"></fa-icon>
        {{counts.suggestions}} suggestions will be automatically applied.
      </div>
      <div *ngIf="hasNewCustomTopic && !isActionDisabled()" class="mt-3">
        'Apply Changes' to combine new custom topics into the model.<br>
        On the next stage of modeling you can further refine them, find more topics or finalize the model.
      </div>
      <div *ngIf="isAtMaxTopicsLimit()" class="mt-3">
        'Find More Topics' is disabled because it would push this model above the {{ query.maxTopics }} topic limit.
      </div>
    </div>
  </ng-container>

  <div class="row">
    <div class="modal-body mx-auto buttonRow trainFinalizeButtonRow">
        <section *ngIf="errorMessage">

          <div class="row buttons">
            <div class="col-6">
              <button [routerLink]="'/platform/lenses/train/' + lens.model.parentLens.id" class="btn btn-primary button-big">
                <fa-icon [icon]="['fal', 'chevron-left']"></fa-icon> TRAIN MODEL
              </button>
            </div>
            <div class="col-6">
              <button #element class="btn btn-primary button-big"
                      (click)="onAction(lens.model.parentLens.id, LensAction.FINISH)">
                FINALIZE
              </button>
            </div>
          </div>
        </section>

        <section *ngIf="!errorMessage">
          <div class="row buttons">
            <div class="col-4">
              <button #element class="btn btn-primary button-big"
                      [disabled]="isActionDisabled()"
                      (click)="onAction(lens.id, LensAction.APPLY)">
                APPLY CHANGES
              </button>
            </div>
            <div class="col-4">
              <button #element class="btn btn-primary button-big"
                      [disabled]="isFindMoreTopicsDisabled()"
                      (click)="onAction(lens.id, LensAction.FIND_TOPICS)">
                FIND MORE TOPICS
              </button>
            </div>
            <div class="col-4">
              <button #element class="btn btn-primary button-big"
                      (click)="onAction(lens.id, LensAction.FINISH)"
                      [disabled]="isFinalizeDisabled()">
                FINALIZE
              </button>
            </div>
          </div>
        </section>
    </div>
  </div>
</section>
