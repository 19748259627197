<section id="unitUploadDragAndDrop">
  <form #unitForm="ngForm">
    <div class="row">
      <div class="modal-header col-12">
        <div class="modal-title">
          Create Data Set Analysis
        </div>
      </div>
    </div>

    <section *ngIf="!isIngestDataSelected">
      <div class="row mt-4">
        <div class="col d-flex align-items-center justify-content-center">
          <button class="btn btn-circle btn-primary" (click)="ingestUpload()">
            <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs></defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Training-Data" transform="translate(-39.000000, -85.000000)" fill="#FFFFFF" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="0.5">
                  <g id="secondary-nav" transform="translate(0.000000, 64.000000)">
                    <g id="add-data-button" transform="translate(30.000000, 12.000000)">
                      <path d="M12.7978055,27.2022917 C12.8741953,27.2786821 12.9744815,27.3170732 13.0747677,27.3170732 C13.1750539,27.3170732 13.2753401,27.2786821 13.35173,27.2022917 L20,20.5539772 L26.64827,27.2022917 C26.7246599,27.2786821 26.8249461,27.3170732 26.9252323,27.3170732 C27.0255185,27.3170732 27.1258047,27.2786821 27.2021945,27.2022917 C27.355366,27.0491192 27.355366,26.801536 27.2021945,26.6483634 L20.5539246,20.000049 L27.2021945,13.3517345 C27.355366,13.198562 27.355366,12.9509788 27.2021945,12.7978062 C27.049023,12.6446337 26.8014415,12.6446337 26.64827,12.7978062 L20,19.4461207 L13.35173,12.7978062 C13.1985585,12.6446337 12.950977,12.6446337 12.7978055,12.7978062 C12.644634,12.9509788 12.644634,13.198562 12.7978055,13.3517345 L19.4460754,20.000049 L12.7978055,26.6483634 C12.6450257,26.8011442 12.6450257,27.0491192 12.7978055,27.2022917 Z" id="add" transform="translate(20.000000, 20.000000) rotate(45.000000) translate(-20.000000, -20.000000) "></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
          <span class="add-data-link" (click)="ingestUpload()">ADD DATA</span>
        </div>
      </div>
    </section>

    <div *ngIf="!isIngestDataSelected" class="row mt-4 mb-4 text-center"k>
      <div class="col-12">
        <span>Or</span>
      </div>
    </div>

    <div class="row">
      <div class="col-8 offset-2">
        <div class="po-form form-group">
          <div class="row mt-4">
            <div class="d-flex justify-content-center mb-3 col-12 userOptions">
              <span class="me-3" [ngClass]="{selected: !(allUsers$ | async)}" >MY DATA</span>
              <mat-slide-toggle
                [checked]="(allUsers$ | async)"
                (change)="setAllUsers($event.checked)"
                color="#33D9CB"></mat-slide-toggle>
              <span class="ms-3" [ngClass]="{selected: (allUsers$ | async)}">ALL DATA</span >
            </div>
          </div>
          <div class="row">
            <div class="col-12 downloadButtons text-center">
              <div ngbDropdown class="d-inline-block w-100 modal-dropdown dropdown">
                <button class="btn btn-outline-gray"
                        id="sourceDropDown"
                        [ngClass]="{isEmpty: selectedCollection == null }"
                        ngbDropdownToggle>
                  {{ selectedCollectionName }}
                  <app-down-arrow></app-down-arrow>
                </button>

                <div ngbDropdownMenu aria-labelledby="sourceDropDown">
                  <div class="scrollItems">
                    <button class="dropdown-item"
                            *ngFor="let collection of collections$ | async | sortByProp: ['id', 'Descending']"
                            (click)="setCollection(collection)">
                      {{collection.name}}
                    </button>
                    <button class="dropdown-item"
                            *ngIf="ingestDataId >= 0"
                            (click)="selectIngestData()">{{ingestDataName}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4 mb-4">
            <div class="col-12 downloadButtons text-center">
              <div ngbDropdown class="d-inline-block w-100 modal-dropdown dropdown">
                <button class="btn btn-outline-gray"
                        id="dropdownBasic1"
                        [ngClass]="{isEmpty: selectedLens == null }"
                        ngbDropdownToggle>
                  {{ dropDownText }}
                  <app-down-arrow></app-down-arrow>
                </button>

                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <div class="scrollItems">
                    <button class="dropdown-item"
                            *ngFor="let lens of lensesCompleted | async | sortByProp: ['id', 'Descending']"
                            (click)="setLens(lens)">{{ lens.name}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-12 input-group">
              <input type="text"
                     id="owner"
                     required name="lens-name"
                     placeholder="Name Your Data Set Analysis"
                     (input)="ngOnChanges()"
                    [(ngModel)]="givenName" class="form-control">
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12 form-group text-center">
              <button type="submit"
                      class="btn btn-primary"
                      (click)="onSubmit()"
                      [disabled]="!unitForm.valid || !isFormSubmitable">
                <fa-icon [icon]="progressIcon" [spin]="isPosting" [hidden]="!isPosting"></fa-icon>
                Create Analysis
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
