<div class="paper">
  <div class="clone-form">

    <div class="exit">
      <span class="btn" (click)="onClose()">
        <fa-icon [size]="'lg'" [icon]="['fal', 'times']"></fa-icon>
      </span>
    </div>

    <h1>Duplicate Stop Word List</h1>

    <div id="stopword"  class="po-form">
      <form>
        <p class="text-center">Please enter a name for your cloned Stop Word List
        </p>
        <div class="form-group">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <input type="text" id="stopword-name" name="stopword-name" placeholder="Stopword List Name" [(ngModel)]="clonedDTO.name"
                       (input)="onNameChange($event)" class="form-control inherit-height text-center" maxlength="35">
                <span *ngIf="dupName" class="name-error">Model name already exists!</span>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="w-100">
        <div class="text-center mb-4 mt-3">
          <button class="btn btn-primary" [disabled]="!isOk()" (click)="clone()">Create Copy</button>
          <app-progress-balls [hidden]="!loading()"></app-progress-balls>
        </div>
      </div>
    </div>
  </div>
</div>
