import {NgModule} from '@angular/core';
import {DocumentViewStateService} from './document-view-state.service';
import {DocumentTopicTextComponent} from './document-topic-text.component';
import {ConversationalDocumentContainerComponent} from './conversational/conversational-document-container/conversational-document-container.component';
import {DocumentFormatControlComponent} from './document-format-control/document-format-control.component';
import {DocumentTopicDistributionComponent} from './document-topic-distribution/document-topic-distribution.component';
import {ResearchDocumentListComponent} from './research/research-document-list/research-document-list.component';
import {ResearchDocumentViewerComponent} from './research/research-document-viewer/research-document-viewer.component';
import {ResearchViewContainerComponent} from './research/research-view-container/research-view-container.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { DocumentTopicDistributionService } from './document-topic-distribution/document-topic-distribution.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    NgbModule,
  ],
  providers: [
    DocumentTopicDistributionService,
    DocumentViewStateService,
  ],
  declarations: [
    ConversationalDocumentContainerComponent,
    DocumentFormatControlComponent,
    DocumentTopicDistributionComponent,
    DocumentTopicTextComponent,
    ResearchDocumentListComponent,
    ResearchDocumentViewerComponent,
    ResearchViewContainerComponent
  ],
  exports: [
    ConversationalDocumentContainerComponent,
    DocumentFormatControlComponent,
    DocumentTopicTextComponent,
    DocumentTopicDistributionComponent,
    ResearchViewContainerComponent
  ]
})
export class DocumentViewModule {}
